.container {
  display: flex;
  flex-direction: column;

  > select {
    margin: 16px 16px 0;
    padding-left: 8px;
    height: 48px;
    color: black;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #cecece;
    background-color: white;
  }
  > .doc {
    padding: 16px;

    h1 {
      margin-top: 16px;
      font-size: 13px;
    }
    h2 {
      margin-top: 8px;
      font-size: 12px;
    }
    p {
      margin-bottom: 8px;
    }
    ol,
    ul {
      list-style: none;
      margin-left: 8px;
    }
    li,
    td,
    p {
      font-size: 11px;
    }
    table {
      border-top: 1px solid black;
      border-left: 1px solid black;
    }
    thead {
      font-weight: bold;
    }
    td {
      padding: 2px 4px;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      word-break: break-all;
    }
  }
}
