.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 64px 16px;

  > .icon {
    width: 80px;
    border: 1px solid lightgray;
    border-radius: 20%;
  }
  > h1 {
    font-size: 24px;
    font-weight: 500;
  }
  > p {
    margin-top: 80px;
    font-size: 16px;
    text-align: center;
    line-height: 1.6;
  }
  > .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .button {
      width: 160px;
    }
  }
}
