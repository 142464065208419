* {
  margin: 0;
  padding: 0;
  word-break: keep-all;
  outline: none;
  user-select: none;
}
body {
  font-family: 'Noto Sans KR', sans-serif;
}
