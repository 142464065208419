.container {
  display: flex;
  flex-direction: column;

  > .image {
    width: 100%;
    max-width: 480px;
    align-self: center;
  }
  > .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;

    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      max-width: 320px;
      height: 56px;
      border-radius: 8px;
    }
  }
  > .dim {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    > .modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 24px 24px;
      width: 80%;
      max-width: 320px;
      border-radius: 12px;
      background-color: white;

      > .message {
        font-size: 20px;
        font-weight: bold;
      }
      > .button {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: white;
        width: 80%;
        max-width: 280px;
        height: 56px;
        border-radius: 8px;
        background-color: #7b00a0;
        letter-spacing: 2px;
      }
    }
  }
}
