.container {
  > img {
    width: 100%;
  }
  > .messageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;
    position: absolute;
    top: 0;

    > .box {
      background-color: rgba(255, 255, 255, 0.5);
      width: 80%;
      padding: 24px 0;
      font-size: 16px;
      text-align: center;
      border-radius: 8px;
      line-height: 1.6;
    }
  }
}
