.container {
  display: flex;
  flex-direction: column;

  > .top,
  > .bottom {
    width: 100%;
  }
  > .wrapper {
    > .board {
      margin: 16px 24px 0;
      background-color: rgba(black, 0.1);
      padding: 8px;
      border-radius: 8px;

      > .cells {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        > .cell {
          display: flex;
          flex-direction: column;
          width: calc(33.3% - 24px);
          background-color: white;
          padding: 0 8px 8px;
          margin: 4px;
          border-radius: 8px;
          position: relative;

          > .name {
            margin-top: 4px;
            font-size: 11px;
            text-align: center;
            word-wrap: break-word;
          }
          > .overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(black, 0.5);
            border-radius: 8px;

            > img {
              transform: rotate(-24deg);
            }
          }
        }
      }
    }
    > .coupons {
      margin: 16px 24px;
      display: flex;
      justify-content: space-between;

      > .coupon {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 0;
        border-radius: 20px;
        background-color: white;
        box-shadow: 1px 1px 2px rgba(black, 0.2);

        &:not(:first-child) {
          margin-left: 12px;
        }
        &.issued {
          box-shadow: none;
          text-decoration-line: line-through;
        }
        &.clickable {
          animation: bounce 0.5s infinite linear;
        }
        > .title {
          font-size: 16px;
          font-weight: bold;
          text-align: center;
        }
        > .label {
          margin-top: 8px;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
